<template>
  <v-timeline dense clipped>
    <v-timeline-item
      large
      v-for="item in items"
      :key="item.id"
      :color="currencyColorMap.get(item.requested.code)"
      :icon="$helpers.getCurrencyIcon(item.requested.code)"
    >
      <v-list dense>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="body-2">
              <span
                v-html="
                  $t('hr.travel.subsistence_message', [
                    `${item.employee.firstName} ${item.employee.lastName}`,
                    $helpers.formattedDate(item.period),
                    $helpers.getConvertCurrency(item.requested)
                  ])
                "
              />
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-timeline-item>
  </v-timeline>
</template>

<script>
  export default {
    name: "TravelSubsistenceTimeline",
    props: {
      items: Array,
      employee: Object
    },
    data: vm => ({
      currencyColorMap: new Map([
        ["TRY", "pink"],
        ["USD", "green"],
        ["EUR", "orange"]
      ])
    })
  };
</script>

<style></style>
