var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-timeline',{attrs:{"dense":"","clipped":""}},_vm._l((_vm.items),function(item){return _c('v-timeline-item',{key:item.id,attrs:{"large":"","color":_vm.currencyColorMap.get(item.requested.code),"icon":_vm.$helpers.getCurrencyIcon(item.requested.code)}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"body-2"},[_c('span',{domProps:{"innerHTML":_vm._s(
                _vm.$t('hr.travel.subsistence_message', [
                  `${item.employee.firstName} ${item.employee.lastName}`,
                  _vm.$helpers.formattedDate(item.period),
                  _vm.$helpers.getConvertCurrency(item.requested)
                ])
              )}})])],1)],1)],1)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }